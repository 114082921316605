import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { Props, IPropsStore, PageFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { name, ReactLoaderForOOISymbol } from './symbols'
import { ComponentWillMount, ViewerComponent } from 'feature-components'
import { OOIComponentLoader, OOIPageConfig } from './types'
import { IViewerBatchingManager, ViewerBatchingManagerSymbol } from 'ds-feature-viewer-manager-action-api'

export const FEED_WIDGET_ID_PROD = '14c1462a-97f2-9f6a-7bb7-f5541f23caa6'

export const ooiComponentWillMount = withDependencies(
	[named(PageFeatureConfigSymbol, name), ReactLoaderForOOISymbol, Props, ViewerBatchingManagerSymbol],
	(
		{ ooiComponents }: OOIPageConfig,
		ooiComponentsLoader: OOIComponentLoader,
		propsStore: IPropsStore,
		viewerBatchingManager: IViewerBatchingManager
	): ComponentWillMount<ViewerComponent<{ widgetId: string }>> => {
		return {
			componentTypes: ['tpaWidgetNative'],
			componentWillMount({ id: compId }) {
				const { widgetId } = ooiComponents[compId]
				const loadComponentPromise = ooiComponentsLoader.getComponent(widgetId).then((ReactComponent) => {
					propsStore.update({
						[compId]: {
							ReactComponent,
						},
					})
				})
				viewerBatchingManager.addRenderBlockingTask(loadComponentPromise)
			},
		}
	}
)
